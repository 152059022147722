.mentions-input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    font-size: 0.9rem;
    line-height: 1.4rem;
    min-height: 64px;
}

.mentions-input.subject {
    padding-top: 8px;
    padding-bottom: 0px;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    font-size: 0.9rem;
    line-height: 1rem;
    min-height: 34px;
}

.mentions-input.subject .mentions-input__input {
    min-height: 24px;
    line-height: 1rem;
}

.mentions-input .mentions-input__input {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    font-size: 0.9rem;
    line-height: 1.4rem;
    --tw-shadow: 0 0 #0000;
    min-height: 64px;
}

.mentions-input.rounded-lg .mentions-input__input {
    border-radius: 0.5rem;
}

.mentions-input.w-full .mentions-input__input {
    width: 100%;
}

.mentions-input.block .mentions-input__input {
    display: block;
}

.mentions-input.rounded-t-none .mentions-input__input {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.mentions-input.rounded-b-none .mentions-input__input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.mentions-input.border-gray-200 .mentions-input__input {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}


.mentions-input .mentions-input__input:focus {
    z-index: 10;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #2563eb;
    
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity));
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}

.mentions-input__suggestions {
    z-index: 100000 !important;
    background: transparent !important;
}