html,
body,
#root {
  height: 100%;
}

* {
  -webkit-tap-highlight-color: transparent;
}

img {
  position: relative;
  font-size: 0;
}

#threshold-fields_input,
#relations-fields_input,
#risks_factors-fields_input {
  width: 100%;
}

.react-flow__attribution {
  display: none;
}

#graph-id {
  width: 100%;
  height: 100%;
}

.ScrollbarsCustom-Track {
  height: 6px !important;
  background-color: rgba(0,0,0,0.05) !important;
}

body.embedded .embedded\:shadow-none {
    box-shadow: none;
}

body.embedded .embedded\:opacity-0 {
    opacity: 0;
}

body.embedded .embedded\:bg-white {
    background-color: #fff;
}

@media (prefers-color-scheme: dark) {
    body.embedded .embedded\:dark\:bg-slate-800 {
        background-color: #2d3748;
    }
}